import { queryClient } from 'AppV2/configs/QueryClient';
import {
  TEMPLATES_KEY_CACHE_QUERY,
  TemplatesKeysEnum,
} from 'AppV2/hooks/TemplatesConfig/types';

const hunters99Colors = {
  templateKey: TemplatesKeysEnum.HUNTERS_99,

  faded: '#FFA564',
  grayFaded: '#00000010',
  white: '#FFFFFF',
  whiteBasic: '#FAFAFA',
  whiteSecondary: '#FCFCFF',
  primary: 'rgba(255, 125, 37, 1)',
  // purple
  purple: 'rgba(255, 125, 37, 1)',
  purple700: 'rgba(255, 125, 37, 0.55)',
  purple600: 'rgba(255, 125, 37, 0.64)',
  purple500: 'rgba(255, 125, 37, 0.7)',
  purple400: '#FF7D25D1',
  purple300: 'rgba(255, 125, 37, 0.7)',
  purple200: 'rgba(255, 125, 37, 0.38)',
  purple100: 'rgba(255, 125, 37, 0.1)',
  purple50: 'rgba(255, 125, 37, 0.08)',
  lightPurple: 'rgba(255, 230, 202, 1)',
  lightPurple50: 'rgba(198, 91, 35, 0.5)',
  lightPurple100: 'rgba(255, 125, 37, 0.1)',
  lightPurple200: 'rgba(255, 125, 37, 0.2)',
  linearPurple:
    'linear-gradient(146deg, rgba(255, 125, 37, 1) 35%, rgba(255, 125, 37, 1) 117%), #ff6700',
  linearPurpleHover:
    'linear-gradient(132.27deg, rgba(255, 125, 37, 1) 54.34%, rgba(255, 159, 20, 1) 94%)',
  linearAlertBar: `linear-gradient(
		90deg,
		#FF4B00 0%,
		#FF7828 26.82%,
		#FF913C 33.8%,
		#FFA550 37.2%,
		#FFC864 41.68%,
		#F0963C 49.56%,
		#FFAA5A 59.9%,
		#FFB964 69.27%,
		#F08C32 77.08%,
		#FFD778 84.9%,
		#FFB950 88.54%,
		#FF6414 94.79%,
		#FF7D25 100%
	);`,
  linearButton: `linear-gradient(
  114deg,
  rgba(255, 125, 37, 0.1) 8.3%,
  rgba(255, 125, 37, 0.1) 25.79%,
  rgba(255, 125, 37, 0.03) 46.99%,
  rgba(255, 125, 37, 0.1) 81.54%
)
`,
  purpleFaded: 'rgba(255, 232, 208, 1)',
  // yellow
  yellow: '#F2BD1D',
  yellow700: '#E5AC00',
  yellow600: '#BC900A',
  yellowAlert300: '#FFD446',
  yellowAlert500: '#FF9D01',
  yellow500: '#D7A204',
  yellow400: '#FED350',
  yellow300: '#FFDB6D',
  yellow200: '#FFE8A0',
  yellow100: '#FFF1C8',
  yellow50: '#FEF8E8',
  lightYellow: '#FBF6E9',
  lightYellow200: 'rgba(242, 189, 29, 0.2)',
  //  blue
  blue: '#4886D9',
  blue700: '#6352FF',
  blue600: '#0064FF',
  blue500: '#3D2B8F',
  blue400: '#69A1EC',
  blue300: '#74A8ED',
  blue200: '#81AEEB',
  blue100: '#D2E0F3',
  blue40: '#0064ff66',
  blue10: '#0064ff1a',
  blueLight: '#4886D910',
  blueLight10: 'rgba(72, 65, 31, 0.1)',
  blueLight30: 'rgba(72, 65, 31, 0.2)',
  blueLight100: '#4469ED',
  blueLight500: '#28AEFF',
  blueLight500ByPrimary: 'rgba(90,130,205,1)',
  blueLight600ByPrimary: '#F17E06',
  blueLight600: '#0698F1',
  blueFaded: '#0064FFBD',
  // green
  greenDark: '#1E8E51',
  green: '#23C965',
  greenBasic: '#39AE6E',
  green500: '#2D9B59',
  green450: '#15C132',
  green400: '#1DF272',
  green300: '#65F59F',
  green200: '#AEF9CC',
  green100: '#D6FFE6',
  green50: '#EDF6F1',
  greenLight: 'rgba(57, 174, 110, 0.10)',
  lightGreen: '#AED111',
  // red
  red: '#F94D4D',
  red700: '#C80D0D',
  red600: '#F83317',
  red600ByPrimary: 'rgba(255, 90, 37, 1)',
  red500: '#D71D1D',
  red400: '#EB5E5E',
  red300: '#F18484',
  red200: '#F0A6A6',
  red100: '#FFD8D8',
  redLight: 'rgba(249, 77, 77, 0.10)',
  // orange
  orange: '#D95E18',
  orange600: '#db560c',
  orange50: '#FCF1ED',
  orange100: '#F9ECE8',
  orange200: '#FF8845',
  orange300: '#F16B1F',
  // dark
  darkText: '#3F3F3F',
  darkPurple: '#3A335A',
  darkGray: '#8692A6',
  darkInput: '#C7C9D9',
  // gray
  gray: '#EAE8F4',
  gray50: '#d4d4de',
  gray100: '#FCFCFF',
  gray200: '#EBEBF1',
  gray300: '#999',
  gray400: '#545454',
  gray500: '#9d9bb0',
  gray700: '#7B7B7B',
  // pink
  pink: '#F90056',
  pink10: '#f900561a',
  pink100: '#D2238E',
  pink200: '#FC80AB',
  pink500: '#FF2468',
  pink700: '#C80852',
  pinkFaded: '#F90056BD',
  pinkLight: '#f900563d',
  // light
  light: '#E2E8ED',
  lightBlue: 'rgba(42, 65, 232, 0.07)',
  lightBlue50: 'rgba(42, 65, 232, 0.5)',
  // backgrounds
  background: 'rgba(255, 230, 202, 1)',
  background50: '#E8E8F8',
  background52: 'rgba(255, 255, 255, 0.52)',
  background76: '#ffffffc2',
  background85: '#FFFFFF85',
  background100: '#FCFCFF',
  background200: '#F2F1F6',
  background300: '#EAE8F4',
  background400: '#E2E0EB',
  background500: '#E5E5E5',
  background600: '#FEFEFE',
  background700: '#F4F4FB',
  backgroundPurpled: '#E7E1FD',
  lightBackground: '#F7F8FA',
  backgroundLightRed: '#FF383714',
  backgroundLightPink: '#FF24681A',
  backgroundLightBlue500: '#6352FF1A',
  backgroundLightgray: '#9999991A',
  backgroundLinearToBottom:
    'linear-gradient(to bottom, rgba(255, 125, 37, 1), rgba(255, 159, 20, 1))',
  // text
  textBlack: '#242322',
  textGray: '#999',
  textLight: '#FCFCFF',
  textBlue: '#4C2AD8',
  // text: '#454157',
  // text50: '#45415780',
  // text100: '#C1C1E4',
  // text200: '#7A6EB3',
  // text300: '#60608C',
  // text400: '#79758C',
  // textPlaceholder: '#ADAABF',
  text: '#B54F1C', // Tom escuro principal, base derivada da primária
  text50: '#B54F1C80', // Text com 50% de opacidade
  text100: '#8F3F17', // Mais escuro ainda, equivalente ao tom do text100
  text200: '#773512', // Tom intermediário escuro
  text300: '#602B0E', // Ainda mais profundo e menos saturado
  text400: '#50240C', // Um passo além, quase marrom escuro
  text500: '#401D0A', // Mais escuro, ótima legibilidade
  textPlaceholder: '#6A3814', // Tom mais neutro mas escuro para placeholders
  // shadows
  buttonShadow: '0px 18px 40px #ff670035',
  buttonBorderedShadow: '0px 0px 6px rgba(142, 121, 235, 0.3)',
  alertSuccessShadow: '0px 18px 40px  rgba(142, 121, 235, 0.35)',
  alertErrorShadow: '0px 18px 40px  rgba(249, 77, 77, 0.12)',
  cardShadow: '0px 2px 4px rgba(142, 121, 235, 0.2)',
  detailCardShadow: '0px 2px 4px rgb(142, 121, 235, 0.3)',
  modalShadow: '0px 4px 54px -13px rgba(65, 37, 194, 0.4)',
  mobileModalShadow: '0px 4px 54px 40px rgba(65, 37, 194, 0.3)',
  darkGrayShadow: '0px 18px 40px 0px rgba(0, 0, 0, 0.19)',
  selectShadow: 'rgba(255, 125, 37, 0.20)',
  popUpShadow: 'rgba(255, 208, 157, 0.5)',
};

const growyxColors = {
  templateKey: TemplatesKeysEnum.GROWYX,

  faded: '#C1C1E4',
  grayFaded: '#00000010',
  white: '#FFFFFF',
  whiteBasic: '#FAFAFA',
  whiteSecondary: '#FCFCFF',
  primary: '#4125C2',
  // purple
  purple: '#4125C2',
  purple700: '#AA45C7',
  purple600: '#60608C',
  purple500: '#7A6EB3',
  purple400: '#563CCC',
  purple300: '#6F57DC',
  purple200: '#8E79EB',
  purple100: '#C6BBF5',
  purple50: '#4125c21a',
  lightPurple: '#eae7f9',
  lightPurple50: 'rgba(198, 187, 245, 0.5)',
  lightPurple100: 'rgba(65, 37, 194, 0.1)',
  lightPurple200: 'rgba(65, 37, 194, 0.2)',
  linearPurple: 'linear-gradient(146deg, #4125c2 35%, #927aff 117%), #4125c2',
  linearPurpleHover: 'linear-gradient(132.27deg, #6951d8 54.34%, #a38cef 94%)',
  linearAlertBar: `linear-gradient(
    90deg,
    #5a16ef 0%,
    #b845f4 26.82%,
    #3e38f4 33.8%,
    #5a64f4 37.2%,
    #d6e7e4 41.68%,
    #322df7 49.56%,
    #ababed 59.9%,
    #ad83e1 69.27%,
    #4f2df5 77.08%,
    #8791eb 84.9%,
    #5b4df4 88.54%,
    #d963e4 94.79%,
    #eccab2 100%
  );`,
  linearButton: `linear-gradient(
    114deg,
    rgba(65, 37, 194, 0.1) 8.3%,
    rgba(65, 37, 194, 0.1) 25.79%,
    rgba(65, 37, 194, 0.03) 46.99%,
    rgba(65, 37, 194, 0.1) 81.54%
  )`,
  purpleFaded: '#edebff',
  // yellow
  yellow: '#F2BD1D',
  yellow700: '#E5AC00',
  yellow600: '#BC900A',
  yellowAlert300: '#FFD446',
  yellowAlert500: '#FF9D01',
  yellow500: '#D7A204',
  yellow400: '#FED350',
  yellow300: '#FFDB6D',
  yellow200: '#FFE8A0',
  yellow100: '#FFF1C8',
  yellow50: '#FEF8E8',
  lightYellow: '#FBF6E9',
  lightYellow200: 'rgba(242, 189, 29, 0.2)',
  //  blue
  blue: '#4886D9',
  blue700: '#6352FF',
  blue600: '#0064FF',
  blue500: '#3D2B8F',
  blue400: '#69A1EC',
  blue300: '#74A8ED',
  blue200: '#81AEEB',
  blue100: '#D2E0F3',
  blue40: '#0064ff66',
  blue10: '#0064ff1a',
  blueLight: '#4886D910',
  blueLight10: 'rgba(72, 134, 217, 0.1)',
  blueLight30: 'rgba(72, 134, 217, 0.3)',
  blueLight100: '#4469ED',
  blueLight500: '#28AEFF',
  blueLight500ByPrimary: 'rgba(80, 60, 220, 1)',
  blueLight600ByPrimary: '#6678F1',
  blueLight600: '#0698F1',
  blueFaded: '#0064FFBD',
  // green
  greenDark: '#1E8E51',
  green: '#23C965',
  greenBasic: '#39AE6E',
  green500: '#2D9B59',
  green450: '#15C132',
  green400: '#1DF272',
  green300: '#65F59F',
  green200: '#AEF9CC',
  green100: '#D6FFE6',
  green50: '#EDF6F1',
  greenLight: 'rgba(57, 174, 110, 0.10)',
  lightGreen: '#AED111',
  // red
  red: '#F94D4D',
  red700: '#C80D0D',
  red600: '#F83317',
  red600ByPrimary: 'rgba(128, 50, 150, 1)',
  red500: '#D71D1D',
  red400: '#EB5E5E',
  red300: '#F18484',
  red200: '#F0A6A6',
  red100: '#FFD8D8',
  redLight: 'rgba(249, 77, 77, 0.10)',
  // orange
  orange: '#D95E18',
  orange600: '#db560c',
  orange50: '#FCF1ED',
  orange100: '#F9ECE8',
  orange200: '#FF8845',
  orange300: '#F16B1F',
  // dark
  darkText: '#3F3F3F',
  darkPurple: '#3A335A',
  darkGray: '#8692A6',
  darkInput: '#C7C9D9',
  // gray
  gray: '#EAE8F4',
  gray50: '#d4d4de',
  gray100: '#FCFCFF',
  gray200: '#EBEBF1',
  gray300: '#999999',
  gray400: '#545454',
  gray500: '#9d9bb0',
  gray700: '#7B7B7B',
  // pink
  pink: '#F90056',
  pink10: '#f900561a',
  pink100: '#D2238E',
  pink200: '#FC80AB',
  pink500: '#FF2468',
  pink700: '#C80852',
  pinkFaded: '#F90056BD',
  pinkLight: '#f900563d',
  // light
  light: '#E2E8ED',
  lightBlue: 'rgba(42, 65, 232, 0.07)',
  lightBlue50: 'rgba(42, 65, 232, 0.5)',
  // backgrounds
  background: '#EAE7F9',
  background50: '#E8E8F8',
  background52: 'rgba(255, 255, 255, 0.52)',
  background76: '#ffffffc2',
  background85: '#FFFFFF85',
  background100: '#FCFCFF',
  background200: '#F2F1F6',
  background300: '#EAE8F4',
  background400: '#E2E0EB',
  background500: '#E5E5E5',
  background600: '#FEFEFE',
  background700: '#F4F4FB',
  backgroundBlue: '#4C2AD8',
  backgroundPurpled: '#E7E1FD',
  lightBackground: '#F7F8FA',
  backgroundLightRed: '#FF383714',
  backgroundLightPink: '#FF24681A',
  backgroundLightBlue500: '#6352FF1A',
  backgroundLightgray: '#9999991A',
  backgroundLinearToBottom:
    'linear-gradient(173deg, #a692fb 10.86%, #6f57dc 89.14%)',
  // text
  textBlack: '#242322',
  textGray: '#999',
  textLight: '#FCFCFF',
  textBlue: '#4C2AD8',
  text: '#454157',
  text50: '#45415780',
  text100: '#C1C1E4',
  text200: '#7A6EB3',
  text300: '#60608C',
  text400: '#79758C',
  text500: '#545161',
  textPlaceholder: '#ADAABF',
  // shadows
  buttonShadow: '0px 18px 40px  rgba(142, 121, 235, 0.35)',
  buttonBorderedShadow: '0px 0px 6px rgba(142, 121, 235, 0.3)',
  alertSuccessShadow: '0px 18px 40px  rgba(142, 121, 235, 0.35)',
  alertErrorShadow: '0px 18px 40px  rgba(249, 77, 77, 0.12)',
  cardShadow: '0px 2px 4px rgba(142, 121, 235, 0.2)',
  detailCardShadow: '0px 2px 4px rgb(142, 121, 235, 0.3)',
  modalShadow: '0px 4px 54px -13px rgba(65, 37, 194, 0.4)',
  mobileModalShadow: '0px 4px 54px 40px rgba(65, 37, 194, 0.3)',
  darkGrayShadow: '0px 18px 40px 0px rgba(0, 0, 0, 0.19)',
  selectShadow: 'rgba(142, 121, 235, 0.35)',
  popUpShadow: '#dbd5f7',
};

const templateColors = {
  [TemplatesKeysEnum.GROWYX]: growyxColors,
  [TemplatesKeysEnum.HUNTERS_99]: hunters99Colors,
};

const templateKey =
  queryClient.getQueryData<TemplatesKeysEnum>(TEMPLATES_KEY_CACHE_QUERY) ||
  sessionStorage.getItem(TEMPLATES_KEY_CACHE_QUERY);

const templateColor = Object.values(TemplatesKeysEnum).includes(
  templateKey as TemplatesKeysEnum,
)
  ? templateColors[templateKey]
  : templateColors[TemplatesKeysEnum.GROWYX];

export const colors = templateColor as typeof growyxColors;
